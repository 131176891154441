import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './Features.module.scss'
import {Images} from "../../constants/images";
import {Icons} from "../../constants/icons";

const pageContents = [
  {
    title: "Technical recruitment",
    description: "Deep knowledge of EMEA and North America markets, proved by over 10 years of experience. Smart pricing approach. Access to premium search tools, extensive networking, and recruiting aggregators",
    image: Images.TechnicalRecruitment
  },
  {
    title: "Recruitment process outsourcing (RPO)",
    description: {
      intro: "Exclusive time-based cooperation, providing comprehensive support for your recruitment process without any per-placement fees:",
      list: [
        "In-depth involvement in your company's recruitment process, functioning as an internal recruiter",
        "Emphasis on building a robust candidate pipeline",
        "A fixed monthly fee per recruiter"
      ]
    },
    image: Images.RPO
  },
  {
    title: "Outstaffing",
    description: "A cost-effective and time-efficient approach to building your dedicated R&D team, especially for short-term projects. You can manage your projects independently, focusing on your current business goals. We will take care of any operational issues.",
    image: Images.Outstaffing
  }
];

interface DescriptionObject {
  intro: string;
  list: string[];
}

type Description = string | DescriptionObject;

const renderDescription = (description: Description) => {
  if (typeof description === 'string') {
    return <p className={styles.description}>{description}</p>;
  } else if (typeof description === 'object' && 'intro' in description && 'list' in description) {
    return (
      <>
        <p className={styles.description}>{description.intro}</p>
        <ul>
          {description.list.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </>
    );
  }
};

const Features = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const featureRef = useRef(null);

  const nextPage = useCallback(() => {
    setCurrentPage(prevPage => (prevPage + 1) % pageContents.length);
  }, []);

  const prevPage = useCallback(() => {
    setCurrentPage(prevPage => (prevPage - 1 + pageContents.length) % pageContents.length);
  }, []);

  useEffect(() => {
    const featureElement = featureRef.current;
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => setIsVisible(entry.isIntersecting));
      },
      {threshold: 0.1}
    );

    if (featureElement) {
      observer.observe(featureElement);
    }

    return () => {
      if (featureElement) {
        observer.unobserve(featureElement);
      }
    };
  }, []);

  useEffect(() => {
    let pageChangeInterval: NodeJS.Timeout | null = null;
    if (isVisible) {
      pageChangeInterval = setInterval(nextPage, 7000);
    }

    return () => {
      if (pageChangeInterval) {
        clearInterval(pageChangeInterval);
      }
    };
  }, [isVisible, nextPage]);

  return (
    <div className={styles.features} ref={featureRef}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <h2 className={styles.title}>
              {pageContents[currentPage].title}
            </h2>
            <p className={styles.description}>
              {renderDescription(pageContents[currentPage].description)}
            </p>

            <div className={styles.btnContainer}>
              <button className={styles.btnArrow} onClick={prevPage}>
                <img src={Icons.ArrowLeft} alt="Button arrow left"/>
              </button>

              <button className={styles.btnArrow} onClick={nextPage}>
                <img src={Icons.ArrowRight} alt="Button arrow right"/>
              </button>
            </div>
          </div>

          <img src={pageContents[currentPage].image} className={styles.image} alt="Dynamic content"/>
        </div>
      </div>
    </div>

  );
};

export default Features;