import React, { FC } from 'react';

interface ISeparatorProps {
  size: number;
  orientation?: 'horizontal' | 'vertical';
  style?: React.CSSProperties;
}

export const Separator: FC<ISeparatorProps> = ({
  size,
  orientation = 'vertical',
  style,
}) => (
  <div
    style={{
      ...(orientation === 'vertical' ? { height: size } : { width: size }),
      ...style,
    }}
  />
);
