import React from 'react';
import styles from "./Office.module.scss";

const Office = () => {
  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h2 className={styles.title}>
            Locations
          </h2>

          <div className={styles.block}>
            <div className={styles.element}>
              <h4 className={styles.officeTitle}>
                United Kingdom
              </h4>
              <p className={styles.officeAddress}>
                20 Wenlock Road, N1 7GU, London
              </p>
            </div>

            <div className={styles.element}>
              <h4 className={styles.officeTitle}>
                Ukraine
              </h4>
              <p className={styles.officeAddress}>
                01054, Kyiv, Turhenevska street, 38, office 102/1
              </p>
            </div>

            <div className={styles.element}>
              <h4 className={styles.officeTitle}>
                United States
              </h4>
              <p className={styles.officeAddress}>
                30 N Gould St, Ste 25950, Sheridan, Wyoming 82801
              </p>
            </div>
          </div>

          <h2 className={styles.callUs}>
            Call us:
          </h2>
          <h4 className={styles.number}>
            Cell, WhatsApp +447539842340
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Office;