import React from 'react';
import styles from './Companies.module.scss'
import {Images} from "../../constants/images";

const companiesLogo = [
  {
    logo: Images.AppsFlyer,
    url: 'https://www.appsflyer.com/',
  },
  {
    logo: Images.BiScience,
    url: 'https://www.biscience.com/'
  },
  {
    logo: Images.Boadrs,
    url: 'https://www.boards.so/'
  },
  {
    logo: Images.CloudBeds,
    url: 'https://www.cloudbeds.com/'
  },
  {
    logo: Images.Fiverr,
    url: 'https://www.fiverr.com/seller_dashboard'
  },
  {
    logo: Images.Lumen,
    url: 'https://lumen.me/'
  },
  {
    logo: Images.PandaDoc,
    url: 'https://www.pandadoc.com/'
  },
  {
    logo: Images.RBI,
    url: 'https://www.rbinternational.com/'
  },
  {
    logo: Images.Roku,
    url: 'https://www.roku.com/'
  },
  {
    logo: Images.SeekingAlpha,
    url: 'https://seekingalpha.com/'
  },
  {
    logo: Images.Similarweb,
    url: 'https://www.similarweb.com/'
  },
  {
    logo: Images.Snap,
    url: 'https://www.snap.com/'
  },
  {
    logo: Images.Trackimo,
    url: 'https://trackimo.com/'
  },
  {
    logo: Images.Walkme,
    url: 'https://www.walkme.com/'
  },
  {
    logo: Images.Wix,
    url: 'https://www.wix.engineering/'
  },
  {
    logo: Images.WOO,
    url: 'https://woo.org/'
  },
]
const Companies = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          Trusted by world-class companies
        </h2>
        <div className={styles.scrollingWrapper}>
          {companiesLogo.concat(companiesLogo).map((company, index) => (
            <a href={company.url} key={index} target="_blank" rel="noopener noreferrer" className={styles.logoLink}>
              <img src={company.logo} alt={`Logo of company ${index + 1}`} className={styles.logo} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Companies;