import Logo from "../assets/logo.svg"
import LogoWhite from "../assets/logo-white.svg"
import Development from "../assets/icons/development.svg"
import NonDevelopment from "../assets/icons/non-development.svg"
import NonTechnical from "../assets/icons/non-technical.svg"
import DevOps from "../assets/icons/dev-ops.svg"
import LeadershipRecruitment from "../assets/icons/leadership-recruitment.svg"
import Testing from "../assets/icons/testing.svg"
import Design from "../assets/icons/design.svg"
import ArrowLeft from "../assets/icons/arrow-left.svg"
import ArrowRight from "../assets/icons/arrow-right.svg"
import Avatar from "../assets/icons/avatar.svg"


export const Icons = {
  Logo,
  LogoWhite,
  Development,
  NonDevelopment,
  NonTechnical,
  DevOps,
  LeadershipRecruitment,
  Testing,
  Design,
  ArrowLeft,
  ArrowRight,
  Avatar
};
