import React from 'react';
import {Icons} from "../../constants/icons";
import styles from "./TechnologyStacks.module.scss"

const technologys = [
  {
    icon: Icons.Development,
    title: 'RnD',
    list: [
      "JavaScript, NodeJS, Java, Scala, Golang, C/C++, C#, Clojure, PHP, Ruby, Python, .NET, BI",
      "Mobile: Android, iOS, React Native, Flutter, Xamarin",
      "Embedded & Hardware: C/C++, Android, Firmware",
      "Crypto & Blockchain: Rust, Solidity, Smart Contract Developers (Solana, Ethereum)",
      "Big Data & ML/Data Scientists",
      "AI Professionals",
      "Security architects and engineers"
    ]
  },
  {
    icon: Icons.NonDevelopment,
    title: 'Non-RnD',
    list: [
      "Business analysts",
      "Product Owners/Product managers",
      "System admins",
      "Network engineers",
      "ERP & DBAs"
    ]
  },
  {
    icon: Icons.NonTechnical,
    title: 'Non-technical',
    list: [
      "Sales & Bizdev, Recruiting/HR, PR/Marketing, Accounting/Finance, Administration",
    ]
  },
  {
    icon: Icons.Testing,
    title: 'Testing',
    list: [
      "QA, AQA, QC, SDET",
    ]
  },
  {
    icon: Icons.DevOps,
    title: 'DevOps',
    list: [
      "DevOps, CloudOps, Site reliability engineers, Infrastructure engineers",
    ]
  },
  {
    icon: Icons.LeadershipRecruitment,
    title: 'Leadership',
    list: [
      "TLs/PMs/SPMs/ Engineering directors",
      "Executive leadership",
    ]
  },
  {
    icon: Icons.Design,
    title: 'Design',
    list: [
      "UX/UI /Creative /Art/ Product Design Manager",
    ]
  }
]

const renderDescription = (list: string[]) => {
  if (list.length > 1) {

    return (
      <ul>
        {list.map((item: string, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  return <p className={styles.description}>{list}</p>;

};

const TechnologyStacks = () => {
  return (
    <div className={styles.background}>
      <div className={styles.clip}>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            Main technology stacks
          </h2>
          <div className={styles.container}>

            {technologys.map((technology) => {
              return (
                <div className={styles.cardContainer}>
                  <div className={styles.card}>
                    <img src={technology.icon} alt={technology.title} className={styles.icon}/>
                    <p className={styles.name}>
                      {technology.title}
                    </p>
                    <div>
                      {renderDescription(technology.list)}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>

  );
};

export default TechnologyStacks;