import React from 'react';
import styles from "./Showcase.module.scss";
import {Images} from "../../constants/images";

const Showcase = () => {
  return (
    <div className={styles.showcase}>
      <div className={styles.wrapper}>
        <div className={styles.container}>

          <div className={styles.card} style={{
            backgroundImage: `url(${Images.TechnicallyMinded})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
            <p className={styles.title}>Technically-minded</p>
            <p className={styles.description}>
              We know your technology stack and speak the same language as your hiring
              managers and candidates
            </p>
          </div>

          <div className={`${styles.card} ${styles.spanTwoRows}`} style={{
            backgroundImage: `url(${Images.Openness})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
            <p className={styles.title}>Openness</p>
            <p className={styles.description}>
              We are committed to open communication. We will provide advice on the recruitment process, benefits, and
              pipeline. Additionally, we will assist you in negotiating job offers with candidates to ensure a win-win
              situation for both parties
            </p>
          </div>

          <div className={styles.card} style={{
            backgroundImage: `url(${Images.Care})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
            <p className={styles.title}>Care</p>
            <p className={styles.description}>
              We are passionate about what we do and care about your open positions as much as you do
            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Showcase;