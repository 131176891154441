import React from 'react';
import styles from './AboutUs.module.scss'
import Grid from "@mui/material/Grid";
import {Images} from "../../constants/images";

const clientsInfo = [
  {
    avatar: Images.BoazInbal,
    name: 'Boaz Inbal',
    characteristic: 'General Manager, Innovation Centers, Wix',
    text: 'We used to work with HiringWise for more than 4 years and during this time we’ve managed to fill more than 100 positions together – 70+ roles in RnD and 30+ in customer care division. The HiringWise team always demonstrates great level of engagement, deep understanding of the technical side of the roles and passion to take every challenge we may offer them I would definitely recommend HiringWise as a reliable recruitment provider',
  },
  {
    avatar: Images.UlyanaStepanovskaya,
    name: 'Ulyana Stepanovskaya',
    characteristic: 'Recruitment team lead, PandaDoc',
    text: 'I really enjoyed working with the team! They are very engaged, data driven, open and reactive to feedback, their work is well-structured and they are always in control of the pipeline. Moreover, they keep a great connection with the candidates, get really deep into company details and are acting just as good as an inhouse recruiters, providing candidates with AALL information needed. We worked around 2 years together – and over this time we’ve reached really good results.',
  },
  {
    avatar: Images.OlenaChernyshenko,
    name: 'Olena Chernyshenko',
    characteristic: 'Talent Acquisition Specialist, Fiverr',
    text: 'HiringWise has been our recruitment partner since 2019 and I highly recommend them! During the whole process, you get the highest quality service and understanding of your business needs. It was our expectation at the beginning of the cooperation that the recruiting process and result would be satisfactory. Apart from being fast and reliable they managed to find candidates that not only match our technical requirements but are also a great culture fit for us. If you want to find valuable team members I would definitely recommend working with HiringWise. If you need the best – you will get the best!',
  },
  {
    avatar: Images.MariiaAntoshchuk,
    name: 'Mariia Antoshchuk',
    characteristic: 'HR Manager, Trackimo',
    text: 'Trackimo has been partnering with HiringWise for almost two years now and it has been a wonderful journey from the very beginning! Extraordinary professionalism combined with an amazing personal approach is exactly what makes the TalentWise team so unique and appealing. We have successfully sourced both R&D and non-R&D employees with the help of HiringWise and we couldn’t have been more satisfied with the outcome!',
  },
  {
    avatar: Images.PrielOved,
    name: 'Priel Oved',
    characteristic: 'HR & Talent Acquisition Manager, Lumen',
    text: 'I highly recommend HiringWise as an agency. They really understood the qualities that we look for in our positions, both professionally and culturally, and that is definitely so special and admired. All the candidates that were submitted to us were great and had a true potential, as opposed to other agencies’ leads. HiringWise helped us understand the market. We feel lucky to work with them',
  },
  {
    avatar: Images.YossiHalevi,
    name: 'Yossi Halevi',
    characteristic: 'VP of RnD, Boards',
    text: 'It is always a pleasure to work with the HiringWise recruiting team. They are very professional, responsive and kind, and we’ve managed to hire great talents together. Highly recommended!',
  },
]

const AboutUs = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          What our clients says about us:
        </h2>

        <Grid container spacing={2}>
          {
            clientsInfo.map((client) => (

              <Grid item sm={12} md={6} key={client.name}>
                <div className={styles.card}>
                  <div className={styles.clientInfoContainer}>
                    <img src={client.avatar} className={styles.avatar} alt="avatar"/>
                    <div>
                      <p className={styles.name}>{client.name}</p>
                      <p className={styles.characteristic}>{client.characteristic}</p>
                    </div>
                  </div>
                  <p className={styles.text}>{client.text}</p>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default AboutUs;