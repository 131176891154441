import React from "react";
import { Icons } from "../../constants/icons";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={Icons.LogoWhite} alt="LogoWhite" />
      </div>
    </div>
  );
};

export default Footer;