import React from 'react';
import './App.css';
import Calendly from "./screens/Calendly";
import Landing from "./screens/Landing";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/calendly" element={<Calendly />} />
      </Routes>
    </Router>
  );
}

export default App;
