import React from 'react';
import {InlineWidget} from "react-calendly";
import styles from './Calendly.module.scss'
import {useLocation} from "react-router-dom";


const Calendly = () => {
  const location = useLocation();
  const { name, email, companyName, prepInfo } = location.state;

  const prefill = {
    name,
    email,
    customAnswers: {
      a1: companyName,
      a2: prepInfo
    }
  };

  return (
    <div className={styles.wrapper}>
      <InlineWidget
        url="https://calendly.com/yevhen-kysylevskyi/30min"
        prefill={prefill}
      />
    </div>
  );
};

export default Calendly;