import React from 'react';
import Main from "../../components/Main";
import Companies from "../../components/Companies";
import Showcase from "../../components/Showcase";
import Features from "../../components/Features";
import TechnologyStacks from "../../components/TechnologyStacks";
import AboutUs from "../../components/AboutUs";
import {Element} from "react-scroll";
import Contact from "../../components/Contact";
import Office from "../../components/Office";
import Footer from "../../components/Footer";

const Landing = () => {
  return (
    <div>
      <Main/>
      <Companies/>
      <Showcase/>
      <Features/>
      <TechnologyStacks/>
      <AboutUs/>
      <Element name="contact">
        <Contact/>
      </Element>
      <Office/>
      <Footer/>
    </div>
  );
};

export default Landing;