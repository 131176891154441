import React, {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from "yup";
import {Snackbar, IconButton, SnackbarContent} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./Contact.module.scss";
import {Separator} from "../Separator";
// import PhoneInput from "react-phone-number-input/input";
import {useNavigate} from "react-router-dom";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  // phone: Yup.string().required("Required"),
  aboutYou: Yup.string(),
});

const Contact: React.FC = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={styles.background}>
      <div className={styles.clip}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.content}>
              <h1 className={styles.title}>Contact us</h1>
              <p className={styles.description}>Get in touch with us using the form below.</p>

              <Formik
                initialValues={{
                  name: '',
                  companyName: '',
                  email: '',
                  // phone: '',
                  aboutYou: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {resetForm}) => {
                  setOpenSnackbar(true);
                  navigate('/calendly', {
                    state: {
                      name: values.name,
                      email: values.email,
                      companyName: values.companyName,
                      prepInfo: values.aboutYou,
                    }
                  });
                  resetForm();
                  // setPhoneNumber('');
                }}
              >
                {({setFieldValue, errors, touched, isSubmitting, setFieldTouched}) => (
                  <Form className={styles.form}>
                    <Separator
                      size={32}
                      orientation={"horizontal"}
                      style={{height: 1, background: "#3D3D3D", width: "100%", margin: "32px 0 16px 0"}}
                    />

                    <div className={styles.block}>
                      <div style={{marginBottom: 32}}>
                        <p className={styles.nameInput}>Name</p>
                        <Field name="name" type="text" placeholder="Enter your name" className={styles.input}/>
                        <ErrorMessage name="name" component="div" className={styles.errorText}/>
                      </div>

                      <div style={{marginBottom: 32}}>
                        <p className={styles.nameInput}>Email address</p>
                        <Field name="email" type="email" placeholder="Enter your email" className={styles.input}/>
                        <ErrorMessage name="email" component="div" className={styles.errorText}/>
                      </div>

                      <div style={{marginBottom: 32}}>
                        <p className={styles.nameInput}>Company name</p>
                        <Field name="companyName" type="text" placeholder="Company name" className={styles.input}/>
                        <ErrorMessage name="companyName" component="div" className={styles.errorText}/>
                      </div>
                    </div>

                    {/*<p className={styles.nameInput}>Number</p>*/}
                    {/*<PhoneInput*/}
                    {/*  placeholder="Enter your phone number"*/}
                    {/*  value={phoneNumber}*/}
                    {/*  onChange={(phoneValue) => {*/}
                    {/*    setPhoneNumber(phoneValue || '');*/}
                    {/*    setFieldValue('phone', phoneValue || '', true);*/}
                    {/*  }}*/}
                    {/*  className={styles.input}*/}
                    {/*/>*/}
                    {/*<ErrorMessage name="phone" component="div" className={styles.errorText} />*/}

                    <p className={styles.nameInput}>
                      Let us know about your project vacant role
                    </p>
                    <Field
                      name="aboutYou"
                      as="textarea"
                      placeholder="Type your message here..."
                      className={styles.textarea}
                    />

                    <Separator size={32} orientation={"vertical"}/>

                    <button type="submit" className={styles.button} disabled={isSubmitting}>Book a Google meet</button>
                  </Form>
                )}
              </Formik>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
              >
                <SnackbarContent
                  message="Your request has been sent successfully!"
                  action={action}
                  sx={{bgcolor: "#4ad25a"}}
                />
              </Snackbar>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Contact;
