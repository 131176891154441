import JobRecruitment from "../assets/images/job-recruitment.svg"
import TechnicalRecruitment from "../assets/images/technical-recruitment.svg"
import RPO from "../assets/images/rpo.svg"
import Outstaffing from "../assets/images/outstaffing.svg"
import TechnicallyMinded from "../assets/images/technically-minded.jpg"
import Openness from "../assets/images/openness.jpg"
import Care from "../assets/images/care.jpg"
import MainBackground from "../assets/images/main-background.svg"

// clients
import BoazInbal from '../assets/images/clients/Boaz Inbal.jpg'
import UlyanaStepanovskaya from '../assets/images/clients/Ulyana Stepanovskaya.jpg'
import OlenaChernyshenko from '../assets/images/clients/Olena Chernyshenko.jpg'
import MariiaAntoshchuk from '../assets/images/clients/Mariia Antoshchuk.jpg'
import PrielOved from '../assets/images/clients/Priel Oved.jpg'
import YossiHalevi from '../assets/images/clients/Yossi Halevi.jpg'

// companies
import AppsFlyer from '../assets/images/companies/AppsFlyer.png'
import BiScience from '../assets/images/companies/BiScience.png'
import Boadrs from '../assets/images/companies/Boadrs.png'
import CloudBeds from '../assets/images/companies/CloudBeds.png'
import Fiverr from '../assets/images/companies/Fiverr.png'
import Lumen from '../assets/images/companies/Lumen.png'
import PandaDoc from '../assets/images/companies/PandaDoc.png'
import RBI from '../assets/images/companies/RBI.png'
import Roku from '../assets/images/companies/Roku.png'
import SeekingAlpha from '../assets/images/companies/SeekingAlpha.png'
import Similarweb from '../assets/images/companies/Similarweb.png'
import Snap from '../assets/images/companies/Snap.png'
import Trackimo from '../assets/images/companies/Trackimo.png'
import Walkme from '../assets/images/companies/Walkme.png'
import Wix from '../assets/images/companies/Wix.png'
import WOO from '../assets/images/companies/WOO.png'

export const Images = {
  JobRecruitment,
  TechnicalRecruitment,
  RPO,
  Outstaffing,
  MainBackground,
  TechnicallyMinded,
  Openness,
  Care,
  BoazInbal,
  UlyanaStepanovskaya,
  OlenaChernyshenko,
  MariiaAntoshchuk,
  PrielOved,
  YossiHalevi,
  AppsFlyer,
  BiScience,
  Boadrs,
  CloudBeds,
  Fiverr,
  Lumen,
  PandaDoc,
  RBI,
  Roku,
  SeekingAlpha,
  Similarweb,
  Snap,
  Trackimo,
  Walkme,
  Wix,
  WOO
};
