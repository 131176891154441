import React from "react";
import styles from "./Main.module.scss";
import {Separator} from "../Separator";
import {Link} from "react-scroll";
import {Icons} from "../../constants/icons";

const Main = () => {
  return (
    <div className={styles.mainBackground}>
      <div className={styles.main} id="about">
        <div className={styles.textContent}>

          <img src={Icons.Logo} alt="Logo" className={styles.logo}/>

          <Separator size={44} orientation="vertical"/>

          <h1 className={styles.title}>
            Your reliable technical recruitment partner
          </h1>

          <p className={styles.secondaryText}>
            HiringWise team is ready to assist High-tech companies in managing their recruiting operations globally.
            HiringWise – for high-tech growth
          </p>

          <Separator size={44} orientation="vertical"/>

          <div className={styles.buttonContainer}>
          <span className={styles.buttonDescription}>
            Need a technical talent to hire?
          </span>
            <Separator size={12} orientation="vertical"/>
            <div>
              <Link to="contact" smooth={true} duration={500}>
                <button className={styles.button}>Get in touch with us!</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
